<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4 pt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="12">
							<pui-text-field
								:id="`description-${modelName}`"
								v-model="model.description"
								:label="$t('form.dock.description')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-select
								:id="`portid-${modelName}`"
								:attach="`portid-${modelName}`"
								:label="$t('form.dock.portname')"
								toplabel
								clearable
								:disabled="!isCreatingElement"
								v-model="model"
								modelName="port"
								:modelFormMapping="{ id: 'portid' }"
								:itemsToSelect="[{ id: model.portid }]"
								itemValue="id"
								itemText="portname"
								required
								reactive
							></pui-select>
						</v-col>
						<v-col cols="4">
							<pui-select
								:id="`initbol-${modelName}`"
								v-model="model"
								:label="$t('form.dock.initbol')"
								:disabled="formDisabled"
								modelName="bollard"
								toplabel
								:modelFormMapping="{ id: 'initbol' }"
								:itemsToSelect="[{ id: model.initbol }]"
								itemValue="id"
								itemText="bolcode"
								:fixedFilter="filterByDock"
								:order="{ orderby: 'asc' }"
							></pui-select>
						</v-col>
						<v-col cols="4">
							<pui-select
								:id="`endbol-${modelName}`"
								v-model="model"
								:label="$t('form.dock.endbol')"
								:disabled="formDisabled"
								modelName="bollard"
								toplabel
								:modelFormMapping="{ id: 'endbol' }"
								:itemsToSelect="[{ id: model.endbol }]"
								itemValue="id"
								itemText="bolcode"
								:fixedFilter="filterByDock"
								:order="{ orderby: 'asc' }"
							></pui-select>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'dockform',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'dock'
		};
	},
	methods: {
		afterGetData() {}
	},
	computed: {
		filterByDock() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'dockid', op: 'eq', data: this.model.id }]
			};
		}
	},
	created() {}
};
</script>
